import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PhonePeCardController from "./PhonePeCardController.web";
const configJSON = require("../config.js");
const phonepayIcon = require("../assets/phonepay.svg");
const stripeIcon = require("../assets/stripe.svg");
const clipboardIcon = require("../assets/clipboard.svg");

export class PhonePeCard extends PhonePeCardController {
  render() {
    const { data, phonepayAccountStatus } = this.props;
    const { id, merchant_id, merchant_salt_key, merchant_key_index, configuration_type, phonepay_variables, phonepay_account_id } = data || {};
    const active = phonepay_variables || (id && merchant_id && merchant_id !== "n/a");
    const isMerchantIdSet = !!merchant_id || !!merchant_salt_key || !!merchant_key_index || merchant_key_index === "n/a" ||merchant_id === "n/a" || merchant_salt_key === "n/a";
    return (
      <div className="payment-card-vertical">
        <div className="card-image">
          <img
            src={phonepayIcon}
            className="payment-card-type"
            data-testid="phonepe-img-icon"
          />
        </div>
        
        <div className="payment-card-details">
          <div className="payment-card-information">
            {!active ? (
              <div className={"payment-card-inactive"}>{configJSON.notActive}</div>
            ) : (
              <div
                className={phonepayAccountStatus ? "payment-card-active-phonepe" : "payment-card-active"}
                style={{ backgroundColor: `${phonepayAccountStatus?.color_code}` }}
              >
                {phonepayAccountStatus ? phonepayAccountStatus?.status : configJSON.active}
              </div>
            )}

            <div className="payment-card-configuration-type"  data-testid="phonepe-card-title">
              {data && configuration_type === "phonepay"
                ? configJSON.phonepayTitle
                : configuration_type === "stripe"
                ? configJSON.stripeTitle
                : configJSON.paymentTitle}
            </div>
            {active ? (
              <>
                <div className="payment-card-card-information">
                  <span className="title">{configJSON.labelmerchantId}:</span>
                  <span className="information">{merchant_id}</span>
                  <IconButton className="clipboard" data-testid="phonepe-img-clipboard">
                    <img src={clipboardIcon} onClick={this.handleClipboard(merchant_id)} />
                  </IconButton>
                </div>
                <div className="payment-card-card-information">
                  <span className="title">{configJSON.labelmerchantSaltKey}:</span>
                  <span className="information">{merchant_salt_key}</span>
                  <IconButton className="clipboard" onClick={this.handleClipboard(merchant_salt_key)}>
                    <img src={clipboardIcon} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className="payment-card-card-not-active">{configJSON.useToGetMessage}</div>
            )}
          </div>
          {phonepay_account_id ? (
            <Button
              target="_blank"
              href={configJSON.viewDashboardLink}
              className="card-button"
              onClick={this.handleClick}
            >
              {configJSON.viewDashboard}
            </Button>
          ) : (
            <Button className="card-button" onClick={this.handleClick}>
              {!isMerchantIdSet ? configJSON.activateAccount : configJSON.editPayment}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
