import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { PhonePePayment as PhonePePaymentType } from "../Types";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  data?: PhonePePaymentType;
  onClick: (id: number, isActivated: boolean) => void;
  phonepepaymentStatus: string;
  phonepayAccountStatus?: any,
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PhonePeCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  }


  handleClick = () => {
    const { data } = this.props;
    const { id, phonepay_variables } = data || {};
    this.props.onClick(id || 1, phonepay_variables || false);
  };

  handleClipboard = (text: any) => () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text)
  };
}
