import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PaymentCardController from "./PaymentCardController.web";
const configJSON = require("../config.js");
const razorpayIcon = require("../assets/razorpay.svg");
const stripeIcon = require("../assets/stripe.svg");
const clipboardIcon = require("../assets/clipboard.svg");

export class PaymentCard extends PaymentCardController {
  render() {
    const { data, razorpayAccountStatus } = this.props;
    const { id, api_secret_key, api_key, configuration_type, razorpay_variables, razorpay_account_id } = data || {};
    const active = razorpay_variables || (id && api_key && api_key !== "n/a");
    const isApiKeySet = !api_key || !api_secret_key || api_key === "n/a" || api_secret_key === "n/a";

    let gateWayIcon;
    if (configuration_type === "razorpay") {
      gateWayIcon = razorpayIcon;
    } else if (configuration_type === "stripe") {
      gateWayIcon = stripeIcon;
    } else {
      gateWayIcon = "";
    }
    console.log("icon inside razr",gateWayIcon)
    return (
      <div className="payment-card-vertical">
        <div className="card-image">
          <img
            src={ data ? gateWayIcon : ""}
            className="payment-card-type"
          />
        </div>
        <div className="payment-card-details">
          <div className="payment-card-information">
            {!active ? (
              <div className={"payment-card-inactive"}>{configJSON.notActive}</div>
            ) : (
              <div
                className={razorpayAccountStatus ? "payment-card-active-razor" : "payment-card-active"}
                style={{ backgroundColor: `${razorpayAccountStatus?.color_code}` }}
              >
                {razorpayAccountStatus ? razorpayAccountStatus?.status : configJSON.active}
              </div>
            )}

            <div className="payment-card-configuration-type">
              {data && configuration_type === "razorpay"
                ? configJSON.razorpayTitle
                : configuration_type === "stripe"
                ? configJSON.stripeTitle
                : configJSON.paymentTitle}
            </div>
            {active ? (
              <>
                <div className="payment-card-card-information">
                  <span className="title">{configJSON.user}</span>
                  <span className="information">{api_key}</span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} onClick={this.handleClipboard(api_key)} />
                  </IconButton>
                </div>
                <div className="payment-card-card-information">
                  <span className="title">{configJSON.password}</span>
                  <span className="information">{configJSON.dummyPassword}</span>
                  <IconButton className="clipboard" onClick={this.handleClipboard(api_secret_key)}>
                    <img src={clipboardIcon} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className="payment-card-card-not-active">{configJSON.useToGetMessage}</div>
            )}
          </div>
          {razorpay_account_id ? (
            <Button
              target="_blank"
              href={configJSON.viewDashboardLink}
              className="card-button"
              onClick={this.handleClick}
            >
              {configJSON.viewDashboard}
            </Button>
          ) : (
            <Button className="card-button" onClick={this.handleClick}>
              {isApiKeySet ? configJSON.activateAccount : configJSON.editPayment}
            </Button>
          )}
        </div>
      </div>
    );
  }
}