import React, { Fragment } from "react";
import PaymentMethodsController from "./PaymentMethodsController";
import withLoader from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import "../styles.css";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {TextField,InputAdornment} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const configJSON = require("../config");

export class PaymentMethods extends PaymentMethodsController {
  render() {

    // @ts-ignore
    const { classes } = this.props;
    const { cod_charges } = this.state;

    let currency_type:any;
    if (typeof window !== 'undefined') {
        currency_type = localStorage.getItem('currency_type')
    }
    return (
      <div>
        <div className="payment-method-container">
          <div className="payment-method-title-container">
            <label className="payment-method-title">{configJSON.enablePaymentMode}</label>
          </div>
          <div className="payment-method-items-container">
            <FormGroup>
              {this.props.paymentMethods.map((paymentMethod) => (
                <Fragment>
                <FormControlLabel
                  key={paymentMethod.id}
                  className="payment-method-items"
                  control={
                    <Checkbox
                      data-testid="payment-method-checkbox"
                      checked={paymentMethod.active}
                      onChange={() => this.handlePaymentMethodChange(paymentMethod)}
                    />
                  }
                  label={paymentMethod.payment_method}
                />
                {paymentMethod.id == 1 && paymentMethod.active && 
                  <div className="base_input_wrapper">
                      <div className="base_input_content">
                          <label>{configJSON.codChargesTitle}</label>
                          <TextField
                              value={cod_charges}
                              onChange={(e) => this.handleCODChargeChange(paymentMethod,e.target.value)}
                              variant="outlined"
                              name='cod_charges'
                              type="number"
                              InputProps={{
                                  startAdornment: <InputAdornment className={classes?.adornment} position="start">{currency_type}</InputAdornment>,
                                  className: classes?.input,
                              }}
                          />
                      </div>
                  </div>
                }
                </Fragment>
                
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default withDialog(withLoader(PaymentMethods));
